import { Component, Vue, Prop, PropSync, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import UserMini from '@/components/user/mini/user-mini.vue';
import dataOptions, { DataOptions } from '@/shared/model/DataOptions';
// import { User } from '@/shared/model/user';
import { Logger } from 'fsts';
import DateUtils from '@/shared/utils/DateUtils';
import TreeNavigator from '@/components/tree-navigator/tree-navigator.vue';
import { TranslateResult } from 'vue-i18n';
import wochenabschluesseFileList, { Wochenabschluesse, HistoriesFileOfEndWeek } from '@/shared/model/wochenabschluesse';
import searchForm from '@/views/home/zr-documents/search-form/search-form.vue';
import searchFormInvoice from '@/views/home/invoices/search-form-invoice/search-form-invoice.vue';
import searchFormVoucher from '@/views/home/change-vouchers/search-form-voucher/search-form-voucher.vue';
import searchFormWochenabschluesse from '@/views/home/wochenabschluesse/search-form-wochenabschluesse/search-form-wochenabschluesse.vue';
import zrNewsSearchForm from '@/views/home/zr-news-list/zr-news-search-form/zr-news-search-form.vue';
import AuthorityUtils from '@/router/authorityUtils';
// import GeneralUtils from '@/shared/utils/generalUtils';
const logger = new Logger('components.d4y-table');
const dateUtils = DateUtils;
const wochenabschluesseData = namespace('wochenabschluesse');

@Component({
  name: 'd4y-table',
  components: {
    TreeNavigator,
    searchForm,
    searchFormInvoice,
    searchFormVoucher,
    searchFormWochenabschluesse,
    zrNewsSearchForm,
  },
})
export default class D4yTable extends Vue {
  @wochenabschluesseData.Action('getHistoryFileOfEndWeek')
  private actionHistoryFileOfEndWeek!: (documentId: string) => Promise<any>;

  @wochenabschluesseData.Action('getListFileOfEndWeek')
  private actionListFileOfEndWeek!: any;

  @wochenabschluesseData.Getter('getWochenabschluesseSearchData')
  private getWochenabschluesseSearchData!: any;
  @wochenabschluesseData.Getter('getWochenabschluesseSearchParams')
  private getWochenabschluesseSearchParams!: any;

  @Prop()
  private items!: any[];

  @Prop()
  private listlogEndWeek!: any[];

  @Prop()
  private SwitchView!: string;

  @Prop({ default: false })
  private isNameAsRef!: boolean;
  @Prop({ default: false })
  private isTitleAsRef!: boolean;
  @Prop({ default: false })
  private isFirstNameAsRef!: boolean;
  @Prop({ default: false })
  private isTextAsRef!: boolean;

  @PropSync('options', {
    default: (): DataOptions => {
      return dataOptions.getDefault;
    },
  })
  private optionsDto!: DataOptions;

  @Prop({ default: () => [''] })
  private filterFields!: string[];

  // TODO: same @Prop here and in the `tree-navigator` component probably better use Vuex state
  @Prop()
  private image?: {
    src: string;
    header: string;
    height: number;
    width: number;
  };

  @Prop()
  private selection?: {
    showSelect: boolean;
    singleSelect: boolean;
    customHeader: boolean;
  };

  // TODO: same @Prop here and in the `tree-navigator` component probably better use Vuex state or another approach
  @Prop({ default: true })
  private expanded!: boolean;
  @Prop({ default: false })
  private tableHasFilterBtn!: boolean;
  @Prop({ default: false })
  private hasFooterBtn!: boolean;
  @Prop({ default: false })
  private isFooterBtnDisabled!: boolean;

  @Prop({ default: () => '' })
  private tableHeaderText!: string;
  @Prop({ default: () => 'search' })
  private searchLabel!: string;

  @Prop()
  private breadcrumbs!: any;
  @Prop({ default: false })
  private allowAdd!: boolean;

  @Prop({ default: false })
  private loading!: boolean;
  @Prop({ default: false })
  private loadingFacet!: boolean;

  @Prop()
  private serverItemsLength!: number;

  @Prop({ default: false })
  allowDelete!: boolean;

  @Prop({ default: false })
  allowEdit!: boolean;

  @Prop({ default: false })
  haveMoreActions!: boolean;
  @Prop({ default: true })
  hasBodySlot!: boolean;

  @Prop({ default: false })
  private isSearchDataEmpty!: boolean;

  @Prop({ default: false })
  private loadingExcel!: boolean;
  @Prop({ default: false })
  private loadingPdf!: boolean;

  @Prop({ default: { menuItems: [], facetSearch: false, menuDisabled: false, hasResults: true, chipData: [] } })
  searchMenuObj!: any;

  @Prop({ default: false })
  private emptyMitgliedListForGsUser!: boolean;
  @Prop({ default: '' })
  private mitgliedList!: string;
  private viewType: number = 0;

  historyFileOfEndWeekList: HistoriesFileOfEndWeek[] = [];

  listFileOfEndWeek: Wochenabschluesse = wochenabschluesseFileList.parse({});
  // @Watch('optionsDto.filter')
  // public onOptionsFilterChanged(newVal: any, oldVal: any) {
  //   logger.debug(`----filter:${oldVal}->${newVal}`);
  //   this.$emit('update:options', this.optionsDto);
  // }
  private searchTerm = '';
  private changeSearch(value: string) {
    // (GSP-074) if `menuDisabled` is true this means that facetSearch was completed and new request is sent (possibly for other field), otherwise (`menuDisabled` == false) user trying to send another request with the same unprocessed result from previous request (not choosing anything)
    if (value.length >= 2 && (value !== this.optionsDto.filter || this.searchMenuObj.menuDisabled)) {
      this.hideSubTanle();
      this.optionsDto.filter = value;
      this.optionsDto.page = 1; // (GSP-082) go to 1st page, when change search word
      this.$emit('update:options', this.optionsDto, 'search');
    }
  }
  private changeSearchInput(value: string) {
    if (value && value.length == 0 && this.optionsDto.filter && this.optionsDto.filter?.length > 0) {
      this.hideSubTanle();
      this.optionsDto.filter = value;
      this.$emit('update:options', this.optionsDto, 'search');
    }
  }

  get tooltipText() {
    return this.isInvoicesRoute
      ? this.$t('tooltip_fields_invoice')
      : this.isVoucherRoutes
      ? this.$t('tooltip_fields_changevoucher')
      : this.isNewsRoute
      ? this.$t('tooltip_fields_news')
      : this.isWochenabschluesseRoutes
      ? this.$t('tooltip_fields_wochenabschluesse')
      : this.$t('tooltip_fields');
  }

  get mitgliedLabel() {
    return this.emptyMitgliedListForGsUser ? this.$t('members_gs') : this.$t('selected_members');
  }

  // TODO: implement distinct logic for `Invoice` and then show all buttons for `Invoices`
  get isInvoicesRoute() {
    return this.$route.fullPath.includes('invoice');
  }
  get isVoucherRoutes() {
    return this.$route.fullPath.includes('voucher');
  }
  get isNewsRoute() {
    return this.$route.fullPath.includes('news');
  }
  get isWochenabschluesseRoutes() {
    return this.$route.fullPath.includes('wochenabschluesse');
  }
  get isDevEnv() {
    console.log('isDevEnv GeneralUtils.isDevEnvironmentStore() :>> ', AuthorityUtils.isDevEnvironmentStore());
    return AuthorityUtils.isDevEnvironmentStore();
  }
  get isStagingEnv() {
    console.log(
      'isStagingEnv GeneralUtils.isStagingEnvironmentStore() :>> ',
      AuthorityUtils.isStagingEnvironmentStore()
    );
    return AuthorityUtils.isStagingEnvironmentStore();
  }

  async mounted() {
    let localStorageSearchValue: string | null;
    if (this.isInvoicesRoute) {
      localStorageSearchValue = localStorage.getItem('invoices-filter');
    } else if (this.isVoucherRoutes) {
      localStorageSearchValue = localStorage.getItem('changeVoucher-filter');
    } else if (this.isWochenabschluesseRoutes) {
      localStorageSearchValue = localStorage.getItem('wochenabschluesse-filter');
    } else {
      localStorageSearchValue = localStorage.getItem('zr-documents-filter');
    }
    //avoid extra query if localStorage is empty
    if (localStorageSearchValue) {
      this.searchTerm = (localStorageSearchValue! + '').trim();
      this.optionsDto.filter = this.searchTerm; // fix reset search selection on sorting on initial load
    }
  }

  private clearAllFilters() {
    this.resetSearchTermAndStorage();
    this.$emit('click:clear-all-filter');
  }

  private resetSearchTermAndStorage() {
    this.searchTerm = '';
    if (this.isInvoicesRoute) {
      localStorage.removeItem('invoices-filter');
      localStorage.removeItem('invoices-chipdata');
    } else if (this.isVoucherRoutes) {
      localStorage.removeItem('changeVoucher-filter');
      localStorage.removeItem('changeVoucher-chipdata');
    } else if (this.isWochenabschluesseRoutes) {
      localStorage.removeItem('wochenabschluesse-filter');
      localStorage.removeItem('wochenabschluesse-chipdata');
    } else {
      localStorage.removeItem('zr-documents-filter');
      localStorage.removeItem('zr-documents-chipdata');
    }
  }

  private resetSearch() {
    this.hideSubTanle();
    this.optionsDto.filter = '';
    this.resetSearchTermAndStorage();
    this.$emit('update:options', this.optionsDto, 'reset');
  }

  private getViewTable(typeView: number) {
    this.hideSubTanle();
    this.$emit('click:loadViewTable', typeView);
  }

  private sortDesc?: string;
  clickSortAsc() {
    var sort: boolean[] = [];
    this.optionsDto.sortBy.forEach((fieldName, i) => {
      sort.push(false);
    });
    this.optionsDto.sortDesc = sort;
  }
  clickSortDesc() {
    var sort: boolean[] = [];
    this.optionsDto.sortBy.forEach((fieldName, i) => {
      sort.push(true);
    });
    this.optionsDto.sortDesc = sort;
  }
  clickSortEmpty() {
    this.optionsDto.sortDesc = [];
    this.optionsDto.sortBy = [];
  }

  // (GSP-100) prevent 3 queries on sorting, don't need `sortBy/sortDesc` watchers, since changes in sorting tracked via  `:options.sync="optionsDto"` (in HTML)
  // make 2 request to backend if in global watch by object
  // when update sortDesc sortBy to changed
  // @Watch('optionsDto.sortBy', { deep: true })
  // public onOptionsSortByChanged(newVal: string[], oldVal: string[]) {
  //   logger.debug(`----sortBy:${oldVal}->${newVal}`);
  //   console.log('sortyBy :>> ');
  //   if (JSON.stringify(newVal) != JSON.stringify(oldVal)) {
  //     logger.debug('+');
  //     this.$emit('update:options', this.optionsDto);
  //   }
  // }
  // @Watch('optionsDto.sortDesc')
  // public onOptionssortDescChanged(newVal: any, oldVal: any) {
  //   const oldValAsString = JSON.stringify(oldVal);
  //   const newValAsString = JSON.stringify(newVal);
  //   console.log('sortyDesc :>> ');
  //   logger.debug(`-od-sortDesc:${oldValAsString}->${newValAsString}`);
  //   if (oldValAsString != newValAsString) {
  //     this.sortDesc = newValAsString;
  //     logger.debug('+');
  //     this.$emit('update:options', this.optionsDto);
  //   }
  // }

  @Watch('optionsDto.page')
  public onOptionsPageChanged(newVal: any, oldVal: any) {
    logger.debug(`----page:${oldVal}->${newVal}`);
    this.$emit('update:options', this.optionsDto);
  }

  get isSortByEmpty() {
    return this.optionsDto.sortBy.length < 1;
  }

  selectItem(item: any) {
    if (!item.value) delete item.item; // remove item if value not selected
    this.$emit('selected:item', item.item);
  }

  /**
   * Check if named slot used in the `d4y-table` implementation in parent components (like `groups`[groups_list in Sketch], etc)
   * @param name (name of the slot)
   */
  private hasSlot(name: string) {
    return !!this.$slots[name] || !!this.$scopedSlots[name];
  }

  @Prop({ default: () => [{ text: 'email', value: 'email' }] })
  private headers!: {
    text: string | any;
    value: string;
    sortable?: boolean;
    width?: string;
  }[];

  @Prop({ default: 'primary' })
  private color!: string;
  get searchListLabel() {
    // if (this.filterFields && this.filterFields.length > 0) label = `${this.$t('search', { 0: this.filterFields })}`;
    // logger.debug(`search label:${this.filterField} -> ${label}`);
    return this.searchLabel;
  }

  formatDate(date: any) {
    var sdate = dateUtils.toDateString(date);
    logger.debug(sdate);
    return sdate;
  }  

  created() {
    this.sortDesc = JSON.stringify(this.optionsDto.sortDesc);
  }

  get isFlexWrapWidth() {
    return this.$vuetify.breakpoint.width < 730;
  }

  excelDownload() {
    this.$emit('excel:download');
  }

  pdfDownload() {
    this.$emit('pdf:download');
  }

  createChangeVoucher() {
    this.$emit('create:change_voucher');
  }

  //#region Methods related to facet search and chips
  searchInRealObjects(item: any) {
    this.$emit('search:chip', item);
    if (item.field !== 'all') {
      this.searchTerm = '';
    }
  }

  removeChip(item: any) {
    this.hideSubTanle();
    this.$emit('remove:chip', item);
  }

  get getMitgliedList() {
    return this.mitgliedList.substring(0, 30) + ' ...';
  }
  //#endregion

  private bereitsstellenIcon = {
    src: 'bereitsstellen.svg',
    header: '',
    height: 32,
    width: 32,
  };
  private bereitsstellenIconDisabled = {
    src: 'bereitsstellen_grey.svg',
    header: '',
    height: 32,
    width: 32,
  };

  titleFile(fileTitle: any) {
    return (
      fileTitle == 'PDF-Belege (ZIP für WaWi)' ||
      fileTitle == 'Auflistung (WaWi, dias.exp)' ||
      fileTitle == 'Avis (WaWi)' ||
      fileTitle == 'PDF-Belege (ZIP zur Ansicht)'
    );
  }

  private fileCSV = {
    src: 'csv.svg',
    header: '',
    height: 32,
    width: 32,
  };

  private filePDF = {
    src: 'pdf.svg',
    header: '',
    height: 32,
    width: 32,
  };

  private fileExcel = {
    src: 'excel.svg',
    header: '',
    height: 32,
    width: 32,
  };

  private fileZIP = {
    src: 'zip.svg',
    header: '',
    height: 32,
    width: 32,
  };

  private expandTable = {
    src: 'expand_table.svg',
    header: '',
    height: 28,
    width: 28,
  };

  private table = {
    src: 'table.svg',
    header: '',
    height: 28,
    width: 28,
  };

  private existExtention(exstention: any) {
    if (exstention != null && exstention.length != 0) {
      if (exstention == 'application/pdf' || exstention == 'text/csv' || exstention == 'application/zip') {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  getDisabled(fileId: any, dateRequest: any) {
    return false;
    // ds, 28.11.2024 -> functionality is temporarily not used as agreed with the customer
    // let dateResponse = this.listlogEndWeek.find((x: any) => x.documentId == fileId)?.date_event;
    // if (dateResponse != undefined) {
    //   let responseDate = new Date(dateResponse).getTime();
    //   let requestDate = new Date(dateRequest).getTime();
    //   if (responseDate > requestDate) {
    //     return false;
    //   }
    // } else if (dateRequest  == null ) {
    //   return false;
    // }
    // return true;
  }

  private onMouseOver(documentId: any) {
    this.actionHistoryFileOfEndWeek(documentId)
      .then((result: any) => {
        this.historyFileOfEndWeekList = result;
      })
      .catch((err: any) => {
        logger.error(err);
      });
  }

  private rowExpanded(zr_number: any) {
    this.listFileOfEndWeek = wochenabschluesseFileList.parse({});
    let expandedLists = document.querySelectorAll<HTMLElement>('.mdi-chevron-down.mdi-flip-v');
    expandedLists.forEach((element) => {
      element.click();
    });
    if (zr_number.value) {
      this.actionListFileOfEndWeek({
        zrNummer: zr_number.item.full_name,
        datum: zr_number.item.end_of_week_date,
        titles: this.getWochenabschluesseSearchData.titles,
        searchFacetFields: this.getWochenabschluesseSearchParams.andClauseFieldsIds,
      })
        .then((result: any) => {
          this.listFileOfEndWeek = result[0];
        })
        .catch((err: any) => {
          logger.error(err);
        });
    }
  }

  private hideSubTanle() {
    let expandedLists = document.querySelectorAll<HTMLElement>('.mdi-chevron-down.mdi-flip-v');
    expandedLists.forEach((element) => {
      element.click();
    });
  }
}