import { MutationTree } from 'vuex';
import { ZrNewsState } from './types';
import Vue from 'vue';
import zrNews, { ZrNews, Value } from '@/shared/model/zrNews';

export const mutations: MutationTree<ZrNewsState> = {
  setZrNews(state, payload: Value) {
    state.ZrNews.items = payload.value.map((x) => zrNews.parse(x));
    state.ZrNews.total = payload['@odata.count'] || 0;
    state.ZrNews.isLoading = false;
  },
  setZrNewsTotal(state, payload: number) {
    state.ZrNews.total = payload;
  },
  setZrNewsIsLoading(state, payload: boolean) {
    state.ZrNews.isLoading = payload;
  },
  setZrNewsSearchData(state, payload: any) {
    state.zrNewsSearchData = payload;
  },



  setNewsInfo(state, payload: any) {
    let news = state.ZrNews.items.find(x=> x.id == payload.zr_news_id);
    // console.log('news :>> ', news);
    if (news && news.news_read_info.length ==0) {
      news.news_read_info.push(payload);
    }
    else if (news && news.news_read_info.length >0) {
      let news_info = news.news_read_info.findIndex(x=> x.id == payload.id);
      // console.log('news_info setNewsInfo :>> ', news_info);
      if (news_info > -1) {
        news.news_read_info.splice(news_info,1,payload);
      }
    }
  },

  // setZrNewsFileByIndex(state, payload: any) {
  //   // console.log('payload :>> ', payload);
  //   // let news = state.ZrNews.items.find(x=> x.id == payload.zr_news_id);
  //   // console.log('news :>> ', Object.assign({},news) );
  //   // console.log('news :>> ', news);
  //   // let newsFile = news?.news_files.findIndex((x:any)=> x.id == payload.id);
  //   // console.log('newsFile :>> ', newsFile);
  //   // if (news && newsFile) {
  //   //   news.news_files.splice(newsFile,1, payload);
  //   // }
  //   // let ind = this.zrNews.news_files.findIndex((x: any)=> x.id == zrNewsDocument.id);
  //   // console.log('ind :>> ', ind);
  //   //  this.zrNews.news_files = this.zrNews.news_files.splice(ind,1, zrNewsDocument);
  // },


  setZrNewsImagesArr(state, data: any) {

    if (state.zrNewsImages[data.id]) {

    } else {
      state.zrNewsImages[data.id] = data.imageLink;
    }

    // state.zrNewsImages = data;
  },
  setUnreadNews(state, payload: number) {
    state.unreadNews = payload;
  },
  setChangeUnreadNews(state, payload: number) {
    if (state.unreadNews == 0 && payload == -1) {
        return;
    }
    state.unreadNews += payload;
  },

};
