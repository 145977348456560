import { render, staticRenderFns } from "./zr-news-search-form.html?vue&type=template&id=56640bf2&scoped=true&"
import script from "./zr-news-search-form.ts?vue&type=script&lang=ts&"
export * from "./zr-news-search-form.ts?vue&type=script&lang=ts&"
import style0 from "./zr-news-search-form.scss?vue&type=style&index=0&id=56640bf2&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56640bf2",
  null
  
)

/* custom blocks */
import block0 from "./zr-news-search-form.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fviews%2Fhome%2Fzr-news-list%2Fzr-news-search-form%2Fzr-news-search-form.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports