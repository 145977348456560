export interface Wochenabschluesse {
  id: string; // ulong
  zr_number: string; // string
  file_name?: string; // string
  file_path?: string; // string
  file_category?: string; // string
  end_of_week_date: string; // System.DateTime
  date_event: string; // System.DateTime
  count_files: number;
  file_title?: string; // string
  file_names: any;
  file_titles: any;
  full_name?: string; // string
  mimetype?: string; // string
  process_type?: string; // string
  username?: string; // string
  file_id?: string; // string
  providable?: boolean; // string
}

export interface ListlogEndWeek {
  documentId: number;
  date_event?: string;
}

export interface HistoriesFileOfEndWeek {
  useremail: string;
  date_event?: string;
  process_type?: string;
}

export interface Value {
  value: Wochenabschluesse[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}

// init api object
function toAPI(data: Partial<Wochenabschluesse>): any {
  return {
    id: data?.id || undefined,
    zr_number: data?.zr_number || '',
    file_name: data?.file_name || '',
    file_path: data?.file_path || '',
    file_category: data?.file_category || '',
    file_title: data?.file_title || '', 
    full_name: data?.full_name || '',    
    end_of_week_date: data?.end_of_week_date || '',       
    date_event: data?.date_event || '',  
    count_files: data?.count_files || 0, 
    mimetype: data?.mimetype || '',  
    username: data?.username || '',       
    process_type: data?.process_type || '', 
    file_id: data?.file_id || '', 
    providable: data?.providable || false, 
    file_names: data?.file_names || [], 
    file_titles: data?.file_titles || [], 
  };
}

function parse(data?: Partial<Wochenabschluesse>): Wochenabschluesse {
  return {
    id: data?.id || '',
    zr_number: data?.zr_number || '',
    file_name: data?.file_name || '',
    file_path: data?.file_path || '',
    file_category: data?.file_category || '',
    file_title: data?.file_title || '',
    full_name: data?.full_name || '',

    end_of_week_date: data?.end_of_week_date || '', 
    date_event: data?.date_event || '',  
    count_files: data?.count_files || 0,  
    mimetype: data?.mimetype || '',
    username: data?.username || '', 
    process_type: data?.process_type || '',
    file_id: data?.file_id || '', 
    providable: data?.providable || false, 
    
    file_names: data?.file_names || [],
    file_titles: data?.file_titles || [], 
  };
}

export default {
  parse,
  toAPI: toAPI,
};
