import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Logger } from 'fsts';
import zrnSearchData, { ZrNewsSearchData } from '@/shared/model/smallPayloadModels/zrNewsSearchData';
import DateField from '@/components/_common/date-field/DateField.vue';
import { statusesWithLocale, zrStatusesList } from '@/shared/model/smallPayloadModels/zrStatus';
import DateUtils from '@/shared/utils/DateUtils';

const logger = new Logger('zr-news-search-form');
const zrDocumentModule = namespace('zrDocument');
const zrNewsModule = namespace('zrNews');
const authModule = namespace('auth');

@Component({
  components: { DateField },
})
export default class ZrNewsSearchForm extends Vue {

  @zrDocumentModule.Action('resetSearchFormData')
  private actionResetSearchFormData!: any;
  @zrNewsModule.Action('updateZrNewsSearchData')
  private actionUpdateZrNewsSearchData!: any;

  @zrDocumentModule.Getter('getSearchData')
  private getSearchData!: any;
  @zrDocumentModule.Getter('getZRDocumentsSearchParams')
  private zrDocumentsSearchParams!: any;

  @zrDocumentModule.Getter('isSearchDataInitLocalStorageEmpty')
  private isSearchDataInitLocalStorageEmpty!: any;
  @zrDocumentModule.Getter('isSearchDataEmpty')
  private isSearchDataEmpty!: any;

  @authModule.Getter('isUserInAzureAdminGroup')
  private isUserInAzureAdminGroupGetter!: any;
  @authModule.Getter('isUserOnlyGs')
  private isUserOnlyGs!: any;

  @authModule.Getter('zrNummer')
  private zrNummer!: any;
  @authModule.Getter('zrNummerWithRoles')
  private zrNummerWithRoles!: any;
  @authModule.Getter('isDevEnv')
  private isDevEnv!: any;


  async mounted() {
  }

  clearAllSuppliers() {
    console.log('clearAllSuppliers :>> ');
  }

  isSearchDataWasCleared = false;
  @Watch('isSearchDataEmpty', { deep: true })
  public async onIsSearchDataEmpty(newVal: boolean, oldVal: boolean) {
    logger.log('isSearchDataEmpty isSearchDataEmpty :>> ', newVal);
    if (newVal == true) {
      this.isSearchDataWasCleared = true; // avoid eternal loop on clearing `zrnSearchData` (watcher glitch without this variable)
      this.zrnSearchData = zrnSearchData.defaultData();
    }
  }

  @Watch('zrnSearchData', { deep: true })
  public async onOptionsFilterChanged(newVal: ZrNewsSearchData, oldVal: ZrNewsSearchData) {
    // avoid loop when `zrnSearchData` was reset
    if (!this.isSearchDataWasCleared) {
      // (GSP-102) Always take into account the `zrNummer` to avoid show all records for `gsUser`
      const payload = Object.assign({}, this.zrnSearchData);
      payload.isUserInAzureAdminGroup = this.isUserInAzureAdminGroupGetter;
      payload.zrNummer = this.getZrNummerDependOnRole();

      this.actionUpdateZrNewsSearchData(payload); // (GSP-075) use `Object.assign` to break reference for `zrnSearchData` to get new data from backend on any input clear or multiple select
    }
    this.isSearchDataWasCleared = false;
  }

  getZrNummerDependOnRole() {
    let result = this.isUserOnlyGs ? (this.zrNummerWithRoles.zrdocumentZrNummer + '') || '-' : this.zrNummer + '';
    return result;
  }

  private clearFacetFilter: boolean = false;
  @Watch('zrDocumentsSearchParams', { deep: true })
  public async onOptionsFacetFilterChanged(newVal: any, oldVal: any) {
    this.clearFacetFilter = newVal.andClauseFieldsIds.length == 0;
  }

  statuses = [
    {
      text: this.$i18n.t(`text_all`), //'Alle',
      value: null,
    },
    {
      text: this.$i18n.t(`read_text`), //'Gelesen',
      value: true,
    },
    {
      text: this.$i18n.t(`unread_text`), //'Offen',
      value: false,
    },
  ];

  favoriteValues = [
    {
      text: this.$i18n.t(`text_all`), //'Alle',
      value: null,
    },
    {
      text: this.$i18n.t(`text_yes`), //'Ja',
      value: true,
    },
    {
      text: this.$i18n.t(`text_no`), //'Nein',
      value: false,
    },
  ];


  zrnSearchData = zrnSearchData.defaultData();

  deltas = [];

  @Watch('$i18n.locale')
  private onLocaleChanged() {
    console.log('locale :>> ', this.$i18n.locale);
    // let backendIndices = this.getZrDocumentsStatuses.map((x: any) => x.index);
    // this.statuses = statusesWithLocale(backendIndices);
    console.log('zrStatusesList :>> ', this.statuses);
  }

  searchArticles() {
    var data = Object.assign({}, this.zrnSearchData); // avoid sending requests when change zrnSearchData (watcher in `my-selection-table` component)
    this.actionUpdateZrNewsSearchData(data);
  }

  changeDate(value: any) {
    this.zrnSearchData.datum = value || undefined; // new Date(value).toJSON();

    // (GSP-102) Always take into account the `zrNummer` to avoid show all records for `gsUser`
    const payload = Object.assign({}, this.zrnSearchData);
    payload.isUserInAzureAdminGroup = this.isUserInAzureAdminGroupGetter;
    payload.zrNummer = this.getZrNummerDependOnRole();

    this.zrnSearchData = Object.assign({}, payload); // workaround (sometimes data change is just ignored by watcher)
  }

  //#region Filtering autocomplete data based on previous autocomplete values
  private setSearchFormFiltersPayload() {
    let newVal = this.zrnSearchData;
    let chipDataString= this.zrDocumentsSearchParams.andClauseFieldsIds.map((el: any) => `${el.chipField}:${el.searchWord}`).join(',');
    let payload: ZrNewsSearchData = {
      status: newVal.status,
      favorite: newVal.favorite,
      datum: newVal.datum,
      isUserInAzureAdminGroup: this.isUserInAzureAdminGroupGetter,
      zrNummer: this.getZrNummerDependOnRole(),
      // chipDataString: chipDataString,
    };
    return payload;
  }

  //#endregion

  searchStatusInput = '';
  searchSupplierInput = '';

  clearSearchInput() {
    this.searchStatusInput = '';
    this.searchSupplierInput = '';
  }

  clearSearchFormData() {
    this.actionResetSearchFormData();
  }

  get isSearchFormHasData() {
    return (
      this.zrnSearchData.status !== undefined ||
      this.zrnSearchData.favorite !== undefined ||
      this.zrnSearchData.datum?.length > 0 ||
      !!this.zrnSearchData.datum
    );
  }
}
