// export interface ZrNewsFacetSearchData extends ZrNewsSearchData {
//   searchWord: string;
//   chipDataString: string;

//   // need all fields from SearchData for proper filtering and correct count numbers
// }

export interface ZrNewsSearchData {
  status: boolean | undefined | null; // true(read)/false(unread)/ALL(undefined)
  favorite: boolean | undefined | null; // true/false/ALL(undefined)
  datum: any; // date
  isUserInAzureAdminGroup?: boolean; // `vme-admin` or `vme-support`(see all but cannot change) groups
  zrNummer?: string;
}

function defaultData(): ZrNewsSearchData {
  return {
    status: undefined,
    favorite: undefined,
    datum: undefined,
    isUserInAzureAdminGroup: undefined,
  };
}

export default {
  defaultData,
};
