import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper, numFields } from '../utils/backendHelper';
import wochenabschluesse, {
  Wochenabschluesse,
  ListlogEndWeek,
  HistoriesFileOfEndWeek,
  Value,
} from '../model/wochenabschluesse';
import ODataFilterBuilder from 'odata-filter-builder';

import {
  WochenabschluesseFacetSearchData,
  WochenabschluesseSearchData,
} from '../model/smallPayloadModels/wochenabschluesseSearchData';
import i18n from '@/i18n';

const searchableColumns: Map<string, string> = new Map([['zr_number', 'end_of_week_date']]);
const defaultOrSearchFields = [''];

export interface BackendWochenabschluesse {
  getWochenabschluesse: (searchParams: SearchParams, searchData?: any) => AxiosPromise<Value>;
  getWochenabschluesseFacetSearch: (facetSearch: WochenabschluesseFacetSearchData) => AxiosPromise<Value>;
  getTitlesMembers(zrNummer: string, isUserInAzureAdminGroupGetter: any, isZrNummerFiltering: any): AxiosPromise<any>;
  getSupplierMembers(
    zrNummer: string,
    isUserInAzureAdminGroupGetter: any,
    titles: string,
    isTitleFiltering: any
  ): AxiosPromise<any>;
  getWochenabschluesseFileDownloadLink(payload: any): AxiosPromise<any>;
  createWochenabschluesseLogRecords(payload: any): AxiosPromise<any>;
  sendWochenabschluesseRabbitMqMess(payload: any): AxiosPromise<any>;
  getListlogEndWeek(zrNummer: string, isUserInAzureAdminGroupGetter: any): AxiosPromise<ListlogEndWeek>;
  getHistoryFileOfEndWeek(fileId: string): AxiosPromise<HistoriesFileOfEndWeek>;
  getListFileOfEndWeek(data: any): AxiosPromise<any>;
}

export const defaultBackendWochenabschluesse: BackendWochenabschluesse = {
  getWochenabschluesse(searchParams: any, searchData?: any): AxiosPromise<Value> {
    var odfb = ODataFilterBuilder('and');
    if (searchParams.andClauseFieldsIds?.length > 0) {
      if (!searchParams.andClauseFieldsIds?.some((x: any) => x.chipField == 'all')) {
        searchParams.orClauseFieldsIds = [];
      } else {
        let allChipField = searchParams.andClauseFieldsIds?.find((x: any) => x.chipField == 'all');
        searchParams.filter = allChipField.searchWord;
        searchParams.orClauseFieldsIds = defaultOrSearchFields;
        localStorage.setItem('wochenabschluesse-filter', allChipField.searchWord);
      }
      let andClauseFieldsIds: Array<any> = searchParams.andClauseFieldsIds;

      andClauseFieldsIds.forEach((element: any, index: number) => {
        const chipField = searchableColumns.has(element.chipField)
          ? searchableColumns.get(element.chipField)
          : element.chipField;
        if (numFields.includes(chipField.toLowerCase())) {
          var resultAnd = new ODataFilterBuilder('and');

          resultAnd.ge(chipField, Math.trunc(+element.searchWord), false);
          resultAnd.lt(chipField, Math.trunc(+element.searchWord + 1), false);
          odfb.and(resultAnd);
        } else if (element.chipField == 'all') {
          // do nothing
        } else if (chipField == 'MemberzrNumber' || chipField == 'EndofWeekDdate') {
          odfb.contains(`tolower(${chipField})`, element.searchWord.toLowerCase());
        }
      });
    }

    searchParams.filter = searchParams.filter?.replace(',', '.'); // with `,` (comma) get parsing Odata error on backend
    let url = DefaultBackendHelper.makeUrl(
      `${URLS.wochenabschluesseOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );

    if (searchData.viewTable == 0) {
      url = url
        .replaceAll('MemberzrNumber', 'full_name')
        .replaceAll('FileNames', 'file_names_list')
        .replaceAll('FileTitle', 'file_titles_list')
        .replaceAll('EndofWeekDdate', 'end_of_week_date');
    } else {
      url = url
        .replaceAll('MemberzrNumber', 'full_name')
        .replaceAll('FileNames', 'file_name')
        .replaceAll('FileTitle', 'file_title')
        .replaceAll('EndofWeekDdate', 'end_of_week_date');
    }
    url = url.replaceAll(
      'contains(tolower(end_of_week_date),',
      "contains(tolower(cast(end_of_week_date, 'Edm.String')),"
    );
    return instance.put<Value>(url, searchData);
  },
  createWochenabschluesseLogRecords(data: any): AxiosPromise<any> {
    return instance.put<any>(`${URLS.wochenabschluesse}/AddLog`, data);
  },
  sendWochenabschluesseRabbitMqMess(data: any): AxiosPromise<any> {
    return instance.put<any>(`${URLS.wochenabschluesse}/SendRabbitMqMess`, data);
  },
  getWochenabschluesseFacetSearch(facetData: WochenabschluesseFacetSearchData): AxiosPromise<any> {
    let url = `${URLS.wochenabschluesse}/GetSearchResult`;
    return instance.put<Value>(url, facetData);
  },
  getTitlesMembers(zrNummer: string, isUserInAzureAdminGroupGetter: any, isZrNummerFiltering: any): AxiosPromise<any> {
    return instance.get<any>(
      `${URLS.wochenabschluesse}/GetTitlesMembers/${zrNummer}/${isUserInAzureAdminGroupGetter}/${isZrNummerFiltering}`
    );
  },
  getListlogEndWeek(zrNummer: string, isUserInAzureAdminGroupGetter: any): AxiosPromise<any> {
    return instance.get<any>(
      `${URLS.wochenabschluesse}/GetListlogEndWeek/${zrNummer}/${isUserInAzureAdminGroupGetter}`
    );
  },
  getSupplierMembers(
    zrNummer: string,
    isUserInAzureAdminGroupGetter: any,
    titles: string,
    isTitleFiltering: any
  ): AxiosPromise<any> {
    return instance.get<any>(
      `${URLS.wochenabschluesse}/GetSupplierMembers/${zrNummer}/${isUserInAzureAdminGroupGetter}/${titles}/${isTitleFiltering}`
    );
  },
  getWochenabschluesseFileDownloadLink(payload: any): AxiosPromise<any> {
    return instance.put<any>(`${URLS.wochenabschluesse}/GetFileDownloadLink`, payload, {
      responseType: 'blob',
    });
  },
  getHistoryFileOfEndWeek(fileId: string): AxiosPromise<HistoriesFileOfEndWeek> {
    return instance.get<any>(`${URLS.wochenabschluesse}/GetHistoriesFileOfEndWeek/${fileId}`);
  },
  getListFileOfEndWeek(data: any): AxiosPromise<any> {
    let url = `${URLS.wochenabschluesse}/GetListFileOfEndWeek`;
    return instance.put<Value>(url, data);
  },
};
