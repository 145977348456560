import GeneralUtils from './generalUtils';

export default class AuthUtils {
  public static getTenantId() {
    let tenantId =
      GeneralUtils.isDevEnvironment() || this.isUrlInclude('dev4you')
        ? GeneralUtils.isLocalDevEnv()
          ? '9d3a2809-e0a2-4cfd-b2ce-bd540b6da009'
          : '2775340b-b251-401c-bcac-e05e5f499a3b'
        : 'f606a097-2d4d-4038-97f3-4287834b31bf'; // Azure tenantId is the same both for multiple `clienId`
    return tenantId;
  }

  public static getClientId() {
    let clientId =
      GeneralUtils.isDevEnvironment() || this.isUrlInclude('dev4you')
        ? GeneralUtils.isLocalDevEnv()
          ? '591cf799-7461-4f0b-bc5f-5044e5684126'
          : '5ee51967-6ecb-4bea-b004-dbd1b766afe5'
        : this.isUrlInclude('piex-prod.meinvme.de') || this.isUrlInclude('gutschriftsprozess.meinvme.de')
        ? 'c1e65296-c7c6-4662-93c2-0771038c4ec5' // Old `Development` enviroment on @Chaslau server, not sure we need it from 2024-09
        : this.isUrlInclude('staging.zr-gs.meinvme')
        ? '4c00701c-1a4d-4e4e-b373-e3054b6f98f4'
        : this.isUrlInclude('prod.zr-gs.meinvme.de')
        ? '20e85b81-d6aa-46f1-88e4-8c3c845b4b11'
        : 'NO ID';
    //        `10210597-b03a-461a-8082-f0853a78b5b2` for Staging env (staging.zr-gs.meinvme.de until 2024-09)

    // from 2024-09 new ClientId for Staging/Production
    // (Staging) Client-ID: 4c00701c-1a4d-4e4e-b373-e3054b6f98f4 URL: https://staging.zr-gs.meinvme.de/
    // (Production) Client-ID: 20e85b81-d6aa-46f1-88e4-8c3c845b4b11 URL: https://prod.zr-gs.meinvme.de/

    return clientId;
  }

  // -PROD- data
  // VME-Admin (AZURE: aad-app-gsproz-vmefa-prod; ID: bb32d32a-39ba-45bf-b91a-86fada943c73) -> see all data
  // VME-Support (AZURE: aad-app-gsproz-vmesupport-prod; ID: 33984c7c-116c-49d4-9f89-dd5f8ff87f11) -> see all data, but only read
  // GS (AZURE: aad-app-gsproz-gsuser-prod; ID: c3f8074c-2fc4-47e9-b58b-6333b936fb4e) -> see all data for the ZR-Nummer, that assigned in AdminPanel (check via API-Endpoint ?)

  //VME-Admin  aad-app-gsproz-vmefa-prod; Group-ID: bb32d32a-39ba-45bf-b91a-86fada943c73 -> see all data
  //VME-Support aad-app-gsproz-vmesupport-prod; Group-ID: 33984c7c-116c-49d4-9f89-dd5f8ff87f11 -> see all data, but only read
  // aad-app-gsproz-gsuser-prod; Group-ID: c3f8074c-2fc4-47e9-b58b-6333b936fb4e  -> see all data for the ZR-Nummer, that assigned in AdminPanel (check via API-Endpoint ?)

  // Default `false` mean user will have `Admin` role locally
  public static isForceOnlyGsUser = false; // by default user is Admin+Support change to `true` to be in ONLY 1 role `GsUser`

  public static getVmeGlobalAdminGroup() {
    let groupId = GeneralUtils.isLocalDevEnv()
      ? 'b9d13f54-7fe5-452c-8b1d-24db1e8c1875'
      : window.location.host.includes('dev4you')
      ? 'bb32d32a-39ba-45bf-b91a-86fada943c73f'
      : 'f5e8fb2f-22f5-4f54-81c5-677a7ff6b6e7';
    let name = GeneralUtils.isLocalDevEnv() ? 'vme-admin' : 'aad-app-gsproz-vmefa-prod';

    if (GeneralUtils.isLocalDevEnv() && this.isForceOnlyGsUser) {
      name = 'vme-admin33'; // can put ANY value except `vme-admin`
      groupId = 'vme-admin33';
    }

    return { name, groupId };
  }
  public static getVmeSupportGroup() {
    let groupId = GeneralUtils.isLocalDevEnv()
      ? 'b9d13f54-7fe5-452c-8b1d-24db1e8c1875'
      : window.location.host.includes('dev4you')
      ? '33984c7c-116c-49d4-9f89-dd5f8ff87f11'
      : '9cd6499f-9f14-47b1-82e4-2607833e15ce';
    let name = GeneralUtils.isLocalDevEnv() ? 'vme-admin' : 'aad-app-gsproz-vmesupport-prod';

    if (GeneralUtils.isLocalDevEnv() && this.isForceOnlyGsUser) {
      name = 'vme-admin33'; // can put ANY value except `vme-admin`
      groupId = 'vme-admin33';
    }

    return { name, groupId };
  }
  public static getVmeSingleCompanyAdminGroup() {
    const groupId = GeneralUtils.isLocalDevEnv()
      ? 'b9d13f54-7fe5-452c-8b1d-24db1e8c1875'
      : window.location.host.includes('dev4you')
      ? 'c3f8074c-2fc4-47e9-b58b-6333b936fb4e'
      : '087861a8-e957-4cdc-a491-105816026e87';
    const name = GeneralUtils.isLocalDevEnv() ? 'vme-admin' : 'aad-app-gsproz-gsuser-prod';

    return { name, groupId };
  }
  public static getVmePortalUserGroup() {
    const groupId = GeneralUtils.isLocalDevEnv()
      ? 'b9d13f54-7fe5-452c-8b1d-24db1e8c1875'
      : window.location.host.includes('dev4you')
      ? 'c3f8074c-2fc4-47e9-b58b-6333b936fb4e'
      : 'ab778d74-cc6b-4c20-a9fe-6845f776284d';
    const name = GeneralUtils.isLocalDevEnv() ? 'vme-admin' : 'aad-app-vme-portal';

    return { name, groupId };
  }

  public static isUrlInclude(value: string): boolean {
    let result = window.location.host.toLowerCase().includes(value);
    return result;
  }
}

// function isUrlInclude(value: string): boolean {
//   let result = window.location.host.toLowerCase().includes(value);
//   return result;
// }
