export enum CONST {
  emptyGuid = '00000000-0000-0000-0000-000000000000', // emptyGuid is used in Routes when create new entity (localhost:3009/list/00000000-0000-0000-0000-000000000000)
  maxFileSize5MB = 5242880, // 5242880 Bytes = 5 Megabytes (MB) // https://www.flightpedia.org/convert/5-megabytes-to-bytes.html
  maxFileSize3MB = 3145728, // 3145728 Bytes = 3 Megabytes (MB) // https://www.flightpedia.org/convert/5-megabytes-to-bytes.html
}

// https://stackoverflow.com/a/75865901/2771704
export const FileMimeType: any = {
  'html': 'text/html',
  'css': 'text/css',
  'csv': 'text/csv',
  'doc': 'application/msword',
  'docx': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'jpg': 'image/jpeg',
  'js': 'application/javascript',
  'txt': 'text/plain',
  'json': 'application/json',
  'pdf': 'application/pdf',
  'png': 'image/png',
  'pps': 'application/vnd.ms-powerpoint',
  'tiff': 'image/tiff',
  'xml': 'text/xml',
  'xls': 'application/vnd.ms-excel',
  'xlsx': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'xsl': 'application/xml',
  'zip': 'application/zip',

  'application/octet-stream': 'other'
};
