import { Component, Vue } from 'vue-property-decorator';
import DialogBoxComponent from '@/components/dialogBox/dialogBox';
import { Action, Getter, namespace, State } from 'vuex-class';
import { Logger } from 'fsts';
import { ROUTES } from '@/router/routesEnum';
import { msalInstance } from 'vue-msal-browser';
import GeneralUtils from '@/shared/utils/generalUtils';
import update from '@/shared/mixins/update';
import LocalStorageUtils from '@/shared/utils/localStorageUtils';

const logger = new Logger('app');
const authModule = namespace('auth');
const zrNewsModule = namespace('zrNews');

@Component({
  components: {},
  mixins: [update],
})
export default class App extends Vue {
  public $refs!: Vue['$refs'] & {
    dialogBox: DialogBoxComponent;
  };
  @State('snackbar')
  private snackbar!: any; //Snackbar
  @Action('hideSnackbar') private releaseSnackbar: any;

  @authModule.Getter('isLoggedIn')
  private isLoggedIn!: boolean;
  @authModule.Getter('getUser')
  private user!: any;
  @authModule.Getter('azureUser')
  private azureUser!: any;
  @authModule.Getter('isUserInAzureAdminGroup')
  private isUserInAzureAdminGroupGetter!: any;
  @authModule.Getter('isUserSuperAdmin')
  private isUserSuperAdmin!: any;
  @authModule.Getter('isUserGsUser')
  private isUserGsUser!: any;
  @authModule.Getter('isUserOnlyGs')
  private isUserOnlyGs!: any;
  @authModule.Getter('isUserOnlyGsAndHasTestZr')
  private isUserOnlyGsAndHasTestZr!: any;
  @authModule.Getter('zrNummerWithRoles')
  private zrNummerWithRoles!: any;

  @zrNewsModule.Action('getNewsCountForUser')
  private actionGetNewsCountForUser: any;
  @zrNewsModule.Getter('getUnreadNews')
  private getUnreadNews: any;

  @authModule.Action('logout')
  private logoutAction: any;
  @authModule.Action('getZrNummer')
  private getZrNummerAction: any;
  @authModule.Action('getSupplierTooltipInfo')
  private getSupplierTooltipInfoAction: any;
  @authModule.Action('getTestInvoiceGsUserZr')
  private getTestInvoiceGsUserZrAction: any;
  @authModule.Action('updateAzureData')
  private updateAzureData: any;
  @authModule.Action('updateInitialRedirect')
  private updateInitialRedirect: any;
  @authModule.Getter('isInitialRedirect')
  private isInitialRedirectGetter!: any;
  @authModule.Getter('isDevEnv')
  private isDevEnv!: any;
  @authModule.Getter('isStagingEnv')
  private isStagingEnv!: any;
  @authModule.Getter('isProductionEnv')
  private isProductionEnv!: any;
  @authModule.Action('getEnvironment')
  private getEnvironment: any;
  @authModule.Getter('environment')
  private environment: any;

  @authModule.Getter('isUserHasRightsForDocuments')
  private isUserHasRightsForDocuments: any;
  @authModule.Getter('isUserHasRightsForInvoices')
  private isUserHasRightsForInvoices: any;
  @authModule.Getter('isUserHasRightsForVouchers')
  private isUserHasRightsForVouchers: any;
  @authModule.Getter('isUserHasRightsForEndOfWeek')
  private isUserHasRightsForEndOfWeek: any;

  get mobile() {
    return this.$vuetify.breakpoint.mdAndDown;
  }

  drawer = false;
  toggleMini = true;
  maxHeight = 32;
  maxWidth = 35;

  header = 'select';
  headerTitle = '';
  isDevelopmentEnv = false;

  leftMenuItemsArr: {
    src: string;
    translateId?: string;
    to?: string;
    class?: string;
    additionalRoute?: string;
  }[] = [
    // {
    //   src: 'ico_select',
    //   translateId: 'select',
    //   to: '/',
    //   additionalRoute: '/zr-document',
    // },
    // {
    //   src: 'ico_select',
    //   translateId: 'table_overview',
    //   to: '/invoices',
    //   class: 'smaller-nav-font',
    //   additionalRoute: '/invoice',
    // },
  ];


  get leftMenuItems() {
    // GSP-107 from 2024-01-24 allow to see `Invoices` for all users (this limitation can be brought back, so commented, not deleted ), if back then comment 1 item in `leftMenuItemsArr`
    // if (this.isUserSuperAdmin || this.isUserOnlyGsAndHasTestZr) {

    //   this.leftMenuItemsArr[1] ={
    //     src: 'ico_select',
    //     translateId: 'table_overview',
    //   to: '/invoices',
    //     class: 'smaller-nav-font',
    //   };

    // }
    // // console.log(!this.isProductionEnv);
    // console.log('this.environment :>> ', this.environment);
    if (this.isUserHasRightsForDocuments) {
      this.leftMenuItemsArr[0] = {
        src: 'ico_select',
        translateId: 'select',
        to: '/',
        additionalRoute: '/zr-document',
      };
    }

    if (this.isUserHasRightsForInvoices) {
      this.leftMenuItemsArr[1] = {
        src: 'ico_select',
        translateId: 'table_overview',
        to: '/invoices',
        class: 'smaller-nav-font',
        additionalRoute: '/invoice',
      };
    }

    if (!!this.environment && !this.isProductionEnv && this.isUserHasRightsForVouchers) {
      // && this.gsUserWithVoucherRules) {
      this.leftMenuItemsArr[2] = {
        src: 'ico_voucher',
        translateId: 'change_voucher',
        to: '/change-vouchers',
        class: 'smaller-nav-font',
        additionalRoute: '/change-voucher',
      };
    }

    if (!!this.environment && this.isUserHasRightsForEndOfWeek) {
      // && this.gsUserWithVoucherRules) {
      this.leftMenuItemsArr[3] = {
        src: 'week_closer',
        translateId: 'week_closer',
        to: '/wochenabschluesse',
        class: 'smaller-nav-font',
        additionalRoute: 'wochenabschluesse',
      };
    }

    return this.leftMenuItemsArr;
  }
  private isPartialRoute(val: any) {
    // console.log('appa val.additionalRoute :>> ', val.additionalRoute);
    if (!val.additionalRoute) {
      return false;
    }
    let route = this.$route.path;
    // console.log('appa route :>> ', route);
    return route.includes(val.additionalRoute);
  }

  getEnvFromBackend() {
    this.getEnvironment().then((result: any) => {
      this.headerTitle = result;
      if (this.headerTitle.toUpperCase() != 'PRODUCTION') {
        this.isDevelopmentEnv = true;
      }
    });
  }


  // TODO: can remove after 03.12.2024 22:00
  newsProdHideLimit = new Date(2024,11,3,22,0); // months from 0, so 11 is December //do 03.12.2024 22:00
  get isProdAfter3Dec() {
    let is4thDec = new Date().getTime() > this.newsProdHideLimit.getTime();
    //  return this.isDevEnv && isMore;
    return (this.isProductionEnv && is4thDec) || !this.isProductionEnv;
  }

  created() {
    console.log('appAPP created :>> ');
    this.getEnvFromBackend();
    LocalStorageUtils.setAzureTokenWithExpireTime();

    console.log('process.env :>> ', process.env);
    console.log('this.$router :>> ', this.$router);
    this.$msal
      .authenticateRedirect()
      .then((azureAuthResult: any) => {
        // logger.log('Azure logging :>> ', azureAuthResult);
        console.log('Azure logging :>> ', azureAuthResult); // logger-log not working in Staging/Prod

        this.updateAzureData(azureAuthResult).then(() => {


          this.getZrNummerAction()
            .then((result: any) => {
              // console.log('roles getZrNummerAction result :>> ', result);
              const zrNummer =
                !result?.data || result?.data?.length > 10 || !!result?.data?.errors ? null : result.data;

              if (result?.data) {
                const zrNummerAzureEmail = {
                  azureEmail: this.azureUser.username,
                  zrNummer: zrNummer,
                };

                localStorage.setItem('zrNummerObj', JSON.stringify(zrNummerAzureEmail));
              }
              // (GSP-117) 2024-03-20 for now load Tooltip info for Supplier ONLY FOR DEV
              this.actionGetNewsCountForUser({contactType: result.contactType});

              if (this.isDevEnv || this.isStagingEnv || this.isProductionEnv) {
                this.getSupplierTooltipInfoAction().then((result: any) => {
                  // console.log('getSupplierTooltipInfo act :>> ', result);
                });
              }
            })
            .then((result: any) => {
              // this.getTestInvoiceGsUserZrAction(); // GSP-107 from 2024-01-24 allow to see `Invoices` for all users (this limitation can be brought back, so commented, not deleted )


            });

          this.handleRedirectAfterAzureLogin();
        });
      })
      .catch((err: any) => {
        logger.error(err);
      });
  }

  private handleRedirectAfterAzureLogin() {
    // old logic
    // const currentRoute = this.$router.currentRoute;
    // const redirectPath = currentRoute.query.redirect;
    // if (currentRoute.name == ROUTES.login && redirectPath) {
    //   this.$router.push({ path: redirectPath + '' });
    // } else if (currentRoute.name == ROUTES.login) {
    //   this.$router.push({ name: ROUTES.home });
    // }
    try {
      let homeNavLink: any = document.querySelector('[href="/"]');
      homeNavLink?.click();

      if (!homeNavLink) {
        console.log('handleRedirectAfterAzureLogin NO homeNavLink :>> ', homeNavLink);
      }

      for (let index = 1; index <= 3; index++) {
        setTimeout(() => {
          this.goToInitialBrowserPath(index);
        }, index * 1000);
      }
    } catch (error) {
      console.log('handleRedirectAfterAzureLogin error :>> ', error);
    }
  }
  goToInitialBrowserPath(index: any) {
    console.log('index goToInitialBrowserPath:>> ', index);
    const initialBrowserPath = localStorage.getItem('initialBrowserPath');
    if (initialBrowserPath) {
      // console.log('this.$route.path :>> ', this.$route.path);
      // console.log('this.$route :>> ', this.$route);
      // console.log('this.$router current :>> ', this.$router.currentRoute.path);
      // console.log('initialBrowserPath :>> ', initialBrowserPath);
      // localStorage.removeItem('initialBrowserPath');
      if (this.$router.currentRoute.path == initialBrowserPath || index == 3) {
        localStorage.removeItem('initialBrowserPath');
        this.updateInitialRedirect(false);
      } else {
        this.$router.push({ path: initialBrowserPath });
      }
    }
  }

  mounted() {
    console.log('app APP mounted :>> ');
    // placing a global component in app.vue.html and adding ref options (e.g. <myComponent ref="mycomp"></myComponent>)
    // then make a ref global:
    this.$confirm.open = this.$refs.dialogBox.openConfirm;
    this.$message.open = this.$refs.dialogBox.openMessageBox;

    const locale = localStorage.getItem('locale') || this.$i18n.locale; // default locale is `DE` see `i18n.ts`
    this.setLocale(locale);
  }

  updated() {
    console.log('app APP updated :>> ');
    if (!this.environment) {
      console.log('app updated: call getEnv again if empty :>> ');
      this.getEnvFromBackend();
    }
  }

  private setLocale(locale: string) {
    localStorage.setItem('locale', locale);
    this.$root.$i18n.locale = locale;
    this.$vuetify.lang.current = locale;
  }

  setHeader(value: string) {
    this.header = value;
    if (value == 'table_overview') {
      this.$confirm.open(this.$t('attention').toString(), this.$t('message_text').toString(), { hasCancelBtn: false });
    }
  }

  get hasAzureUser() {
    return this.azureUser?.name;
  }

  get isHasRightsToSee() {
    return this.isUserInAzureAdminGroupGetter || this.isUserGsUser;
  }

  async logout() {
    // await this.logoutAction();
    await this.$msal.logoutRedirect();

    this.$router.push({ name: ROUTES.login });
  }
}
