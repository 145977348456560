import { ActionTree } from 'vuex';
import { WochenabschluesseState } from './types';
import { RootState } from '../../types';
import { defaultBackendWochenabschluesse } from '@/shared/backend/wochenabschluesse';
import { Logger } from 'fsts';
import module_utils from '../module_utils';
import wochenabschluesse, { Value, Wochenabschluesse, ListlogEndWeek, HistoriesFileOfEndWeek } from '@/shared/model/wochenabschluesse';
import {
  WochenabschluesseFacetSearchData,
  WochenabschluesseSearchData,
} from '../../../model/smallPayloadModels/wochenabschluesseSearchData';
import DownloadUtils from '@/shared/utils/DownloadUtils';
import { AxiosResponse } from 'axios';
import i18n from '@/i18n';

const logger = new Logger('actions.changeVouchers');
export const actions: ActionTree<WochenabschluesseState, RootState> = {
  getWochenabschluesse(
    { commit, dispatch, getters, rootGetters },
    payload?: { searchParams?: any; wochenabschluesseSearchData?: any }
  ) {
    commit('setWochenabschluesseIsLoading', true);
    let searchParams = payload?.searchParams ?? getters.getWochenabschluesseSearchParams;
    let searchData = payload?.wochenabschluesseSearchData ?? {};
    return defaultBackendWochenabschluesse
      .getWochenabschluesse(searchParams, searchData)
      .then((response: AxiosResponse<Value>) => {
        commit('setZRWochenabschluesse', response.data);
        commit('setWochenabschluesseIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setWochenabschluesseIsLoading', false);
        module_utils.error('getZRDocuments error', commit, e, logger);
        throw e;
      });
  },
  getTitlesMembers(
    { commit, dispatch },
    payload: { zrNummer: string; isUserInAzureAdminGroupGetter: any; isZrNummerFiltering: any }
  ) {
    return defaultBackendWochenabschluesse
      .getTitlesMembers(payload.zrNummer, payload.isUserInAzureAdminGroupGetter, payload.isZrNummerFiltering)
      .then((response: AxiosResponse<Wochenabschluesse>) => {
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getSupplierMembers(
    { commit, dispatch },
    payload: { zrNummer: string; isUserInAzureAdminGroupGetter: any; titles: string; isTitleFiltering: any }
  ) {
    return defaultBackendWochenabschluesse
      .getSupplierMembers(
        payload.zrNummer,
        payload.isUserInAzureAdminGroupGetter,
        payload.titles,
        payload.isTitleFiltering
      )
      .then((response: AxiosResponse<Wochenabschluesse>) => {
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getWochenabschluesseFacetSearch({ commit, dispatch }, payload: WochenabschluesseFacetSearchData) {
    return defaultBackendWochenabschluesse
      .getWochenabschluesseFacetSearch(payload)
      .then((response: AxiosResponse<any>) => {
        logger.log('getInvoiceFacetSearch response :>> ', response);
        const result = response.data.$values ? response.data.$values : response.data;
        return result;
      })
      .catch((e: any) => {
        module_utils.error('getInvoicesFacet error', commit, e, logger);
        throw e;
      });
  },
  async getWochenabschluesseFileDownloadLink({ commit }, payload: any) {
    try {
      return await defaultBackendWochenabschluesse.getWochenabschluesseFileDownloadLink(payload).then((response) => {
        let fileName = DownloadUtils.getFileName(response.headers['content-disposition']);
        const type = response.headers['content-type'];
        const blob = new Blob([response.data], { type: type });
        if (payload.IsSaveOnDisk) {
          commit('setIsWochenabschluesseFileDownload', true);
          const url = DownloadUtils.createDownloadLink(blob, fileName);
          window.URL.revokeObjectURL(url);
          return fileName;
        }

        var urlCreator = URL.createObjectURL(blob);
        module_utils.ok(i18n.t(`success.getNewsFileDownloadLink`), commit, logger);
        return urlCreator;
      });
    } catch (e) {
      commit('setIsWochenabschluesseFileDownload', false);
      logger.error(e);
      module_utils.error(i18n.t(`error.getNewsFileDownloadLink`), commit, e, logger);
      throw e;
    }
  },
  createWochenabschluesseLogRecords({ commit, dispatch }, payload: any) {
    return defaultBackendWochenabschluesse
      .createWochenabschluesseLogRecords(payload)
      .then((response: AxiosResponse<any>) => {
        return response;
      })
      .catch((e: any) => {
        module_utils.error('createWochenabschluesseLogRecords error', commit, e, logger);
        throw e;
      });
  },
  sendWochenabschluesseRabbitMqMess({ commit, dispatch }, payload: any) {
    return defaultBackendWochenabschluesse
      .sendWochenabschluesseRabbitMqMess(payload)
      .then((response: AxiosResponse<any>) => {
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('send WochenabschluesseRabbitMqMess error', commit, e, logger);
        throw e;
      });
  },
  getListlogEndWeek(
    { commit, dispatch },
    payload: { zrNummer: string; isUserInAzureAdminGroupGetter: any }
  ) {
    return defaultBackendWochenabschluesse
      .getListlogEndWeek(payload.zrNummer, payload.isUserInAzureAdminGroupGetter)
      .then((response: AxiosResponse<ListlogEndWeek>) => {        
        return response.data;
      })
      .catch((e: any) => {
          module_utils.error('getListlogEndWeek error', commit, e, logger);
        throw e;
      });
  },
  getHistoryFileOfEndWeek({ commit }, fileId: string) {
    return defaultBackendWochenabschluesse
      .getHistoryFileOfEndWeek(fileId)
      .then((response: AxiosResponse<HistoriesFileOfEndWeek>) => {
          return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      })
      .finally(() => {
      });
  },
  getListFileOfEndWeek({ commit }, payload?: any) {
    return defaultBackendWochenabschluesse
      .getListFileOfEndWeek(payload)
      .then((response: AxiosResponse<Wochenabschluesse>) => {
          return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      })
      .finally(() => {
      });
  },
  updateWochenabschluesseSearchData({ commit, dispatch }, data: any) {
    commit('setWochenabschluesseSearchData', data);
  },
  resetwochenabschluesseSearchFormData({ commit, dispatch }, data: any) {
    commit('resetwochenabschluesseSearchFormData', data);
  },
  updateInitialLocalStorageWochenabschluesseSearchData({ commit, dispatch }, data: any) {
    commit('setInitialLocalStorageWochenabschluesseSearchData', data);
  },
};
