import { ActionTree } from 'vuex';
import { UserDataState } from './types';
import { RootState } from '../../types';
import { defaultBackendUserData } from '@/shared/backend/userData';
import { Logger } from 'fsts';
import module_utils from '../module_utils';
import userData, { Value, UserData } from '@/shared/model/userData';
import { AxiosResponse } from 'axios';
import i18n from '@/i18n';

const logger = new Logger('actions.userData');
export const actions: ActionTree<UserDataState, RootState> = {
  getUsersData({ commit, dispatch, getters, rootGetters }, payload?: { searchParams?: any }) {
    commit('setUserDataIsLoading', true);
    let searchParams = payload?.searchParams ?? getters.getUserDataSearchParams;
    // let organisationId = rootGetters[CONST.getOrganisation].id;
    return defaultBackendUserData
      .getUserData(searchParams)
      .then((response: AxiosResponse<any>) => {
        commit('setUserData', response.data);
        commit('setUserDataIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setUserDataIsLoading', false);
        module_utils.error('getUsersData error', commit, e, logger);
        throw e;
      });
  },
  getUserData({ commit, dispatch }, id: string) {
    return defaultBackendUserData
      .getUserData(id)
      .then((response: AxiosResponse<UserData>) => {
        commit('setUserDatum', response.data);
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('getUserData error', commit, e, logger);
        throw e;
      });
  },
  updateUserData({ commit, dispatch, getters }, file: UserData) {
    let userData: UserData = getters.getUserDatum;
      console.log('updateUserData getter userDatum :>> ', userData, '   userData?.id  ', userData?.id);
    if (userData?.id) {
      file.id = userData.id;
    }
    return defaultBackendUserData
      .updateUserData(file)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: UserData;
          }>
        ) => {
          console.log('updateUserData payload :>> ');
          // module_utils.ok(i18n.tc(`success.userData_update`), commit);
          //logger.info('success.userData_update: -->', response.data);
          commit('setUserDatum', response.data.result);
          return response.data;
        }
      )
      .catch((e: any) => {
        console.log('updateUserData payload :>> ', commit, e, logger);
        //module_utils.error('updateUserData payload error', commit, e, logger);
        throw e;
      });
  },
  deleteUserData({ commit, dispatch }, id: string) {
    return defaultBackendUserData
      .deleteUserData(id)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: UserData;
          }>
        ) => {
          return response;
        }
      )
      .catch((e: any) => {
        module_utils.error('deleteUserData error', commit, e, logger);
        throw e;
      });
  },
};
