export interface WochenabschluesseFacetSearchData extends WochenabschluesseSearchData {
  searchWord: string;
  chipDataString: string;

  // need all fields from SearchData for proper filtering and correct count numbers
}

export interface WochenabschluesseSearchData {
  titles: Array<any>;
  receiverNumbers: Array<any>; // mitglied
  datum: any; // date
  zrNummer?: string;
  chipDataString?: string;
  isShowAllData?: boolean;
  viewTable: number;
  searchFacetFields: Array<any>;
  isUserInAzureAdminGroup?: boolean; // `vme-admin` or `vme-support`(see all but cannot change) groups
  triggerChange?: boolean; // (GSP-116) need this artificial field to call backend when change bruto/netto in popup menu (othewise result somehow cached by reference and backend for new Netto/Brutto not called, since check object changes to avoid extra queries)
}

function defaultData(): WochenabschluesseSearchData {
  return {
    titles: [],
    receiverNumbers: [],
    datum: undefined,
    isShowAllData: true,
    viewTable: 0,
    searchFacetFields: [],
    isUserInAzureAdminGroup: undefined,
    triggerChange: false,
  };
}

export default {
  defaultData,
};
