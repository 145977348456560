import zrnSearchData from '@/shared/model/smallPayloadModels/zrNewsSearchData';
import { ZrNewsState } from './types';

export const state = (): ZrNewsState => initialState();

export const initialState = (): ZrNewsState => ({
  isTableView: false,
  zrNewsSearchData: zrnSearchData.defaultData(),
  zrNewsImages: {},
  unreadNews: 0,
  ZrNews: {
    //TODO remove when was made backend
    items: [],
    isLoading: undefined,
    total: 2, //TODO remove when was made backend
    searchParams: {
      dataOption: {
        page: 1,
        itemsPerPage: 12,
        sortBy: ['created_on'],
        sortDesc: [true],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false,
      },
      filter: '',
      orClauseFieldsIds: ['title','text','created_by'],
      andClauseFieldsIds: [],
    },
  },
});
