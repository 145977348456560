import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Logger } from 'fsts';
import searchData, { SearchData } from '@/shared/model/smallPayloadModels/searchData';
import wochenabschluesseSearchData, {
  WochenabschluesseSearchData,
} from '@/shared/model/smallPayloadModels/wochenabschluesseSearchData';
import DateField from '@/components/_common/date-field/DateField.vue';
import DateUtils from '@/shared/utils/DateUtils';

const logger = new Logger('search-form');
const authModule = namespace('auth');
const changeVoucherModule = namespace('changeVoucher');
const wochenabschluesseData = namespace('wochenabschluesse');

@Component({
  components: { DateField },
})
export default class SearchFormEndOfWeek extends Vue {
  @Ref('rechnungsnummerCombobox')
  private refRechnungsnummerCombobox!: any;
  @Ref('statusesCombobox')
  private refStatusesCombobox!: any;
  @Ref('membersCombobox')
  private refMembersCombobox!: any;

  @Ref('titlesCombobox')
  private refTitlesCombobox!: any;

  @wochenabschluesseData.Getter('getWochenabschluesseSearchParams')
  private zrWochenabschluesseSearchParams!: any;

  @authModule.Getter('isUserInAzureAdminGroup')
  private isUserInAzureAdminGroupGetter!: any;
  @authModule.Getter('isUserOnlyGs')
  private isUserOnlyGs!: any;

  @authModule.Getter('zrNummer')
  private zrNummer!: any;
  @authModule.Getter('zrNummerWithRoles')
  private zrNummerWithRoles!: any;

  @wochenabschluesseData.Action('getSupplierMembers')
  private actionGetSupplierMembers!: any;

  @wochenabschluesseData.Action('updateWochenabschluesseSearchData')
  private actionUpdateWochenabschluesseSearchData!: any;
  @wochenabschluesseData.Action('getTitlesMembers')
  private actionGetTitlesMembers!: any;
  @wochenabschluesseData.Getter('isWochenabschluesseSearchDataEmpty')
  private isWochenabschluesseSearchDataEmpty!: any;
  @wochenabschluesseData.Getter('resetwochenabschluesseSearchFormData')
  private actionResetwochenabschluesseSearchFormData!: any;

  @wochenabschluesseData.Getter('isWochenabschluesseSearchDataInitLocalStorageEmpty')
  private isWochenabschluesseSearchDataInitLocalStorageEmpty!: any;

  @wochenabschluesseData.Getter('getWochenabschluesseSearchData')
  private getWochenabschluesseSearchData!: any;

  @wochenabschluesseData.Action('updateInitialLocalStorageWochenabschluesseSearchData')
  private actionUpdateInitialLocalStorageWochenabschluesseSearchData!: any;

  get isSingleZrNummer() {
    return !!this.zrNummer && !this.zrNummer.includes(',');
  }

  created() {
    if (!this.getWochenabschluesseSearchData) {
      this.getWochenabschluesseSearchData = wochenabschluesseSearchData.defaultData(); // `{}` returned  error
    }
  }

  async mounted() {
    var promiseAll = [];
    promiseAll.push(this.getMembers(), this.getTitles());

    const localStorageWochenabschluesseSearchData = localStorage.getItem('wochenabschluesseSearchData');
    if (!!localStorageWochenabschluesseSearchData) {
      this.actionUpdateInitialLocalStorageWochenabschluesseSearchData(localStorageWochenabschluesseSearchData);
    }
    if (localStorageWochenabschluesseSearchData && !this.isWochenabschluesseSearchDataInitLocalStorageEmpty) {
      this.isSearchDataWasCleared = true; // avoid extra call to backend

      let obj = JSON.parse(localStorageWochenabschluesseSearchData);
      this.wochenabschluesseSearchData = Object.assign({}, obj);

      this.$nextTick(() => {
        this.wochenabschluesseSearchData = Object.assign({}, obj); // fix strange bug that data not load in inputs in `search-forms` from data
      });
    }
  }

  isSearchDataWasCleared = false;
  @Watch('isWochenabschluesseSearchDataEmpty', { deep: true })
  public async onIsWochenabschluesseSearchDataEmpty(newVal: boolean, oldVal: boolean) {
    logger.log('on Is WochenabschluesseSearchDataEmpty on Is WochenabschluesseSearchDataEmpty :>> ', newVal);
    if (newVal == true) {
      this.isSearchDataWasCleared = true; // avoid eternal loop on clearing `searchData` (watcher glitch without this variable)
      this.wochenabschluesseSearchData.datum = wochenabschluesseSearchData.defaultData().datum;
      this.wochenabschluesseSearchData.receiverNumbers = wochenabschluesseSearchData.defaultData().receiverNumbers;
      this.wochenabschluesseSearchData.titles = wochenabschluesseSearchData.defaultData().titles;
      this.wochenabschluesseSearchData.isUserInAzureAdminGroup =
        wochenabschluesseSearchData.defaultData().isUserInAzureAdminGroup;
    }
  }

  @Watch('wochenabschluesseSearchData', { deep: true })
  public async onOptionsFilterChanged(newVal: WochenabschluesseSearchData, oldVal: WochenabschluesseSearchData) {
    // avoid loop when `searchData` was reset
    if (!this.isSearchDataWasCleared) {
      // (GSP-102) Always take into account the `zrNummer` to avoid show all records for `gsUser`
      let payload = Object.assign({}, this.wochenabschluesseSearchData);
      payload.isUserInAzureAdminGroup = this.isUserInAzureAdminGroupGetter;
      payload.zrNummer = this.getZrNummerDependOnRole();
      payload.receiverNumbers = this.wochenabschluesseSearchData.receiverNumbers;
      payload.titles = this.wochenabschluesseSearchData.titles;
      payload.isShowAllData = this.wochenabschluesseSearchData.isShowAllData;
      this.actionUpdateWochenabschluesseSearchData(payload); // (GSP-075) use `Object.assign` to break reference for `searchData` to get new data from backend on any input clear or multiple select
    }
    this.isSearchDataWasCleared = false;
  }

  getZrNummerDependOnRole() {
    let result = this.isUserOnlyGs ? this.zrNummerWithRoles.endOfWeekZrNummer + '' || '-' : this.zrNummer + '';
    return result;
  }

  private clearFacetFilter: boolean = false;
  @Watch('zrWochenabschluesseSearchParams', { deep: true })
  public async onOptionsFacetFilterChanged(newVal: any, oldVal: any) {
    this.clearFacetFilter = newVal.andClauseFieldsIds.length == 0;
  }

  private loadingMembers = false;
  private async getMembers() {
    let zrNummer = this.getZrNummerDependOnRole();
    this.actionGetSupplierMembers({
      zrNummer: zrNummer,
      isUserInAzureAdminGroupGetter: this.isUserInAzureAdminGroupGetter,
      titles: null,
      isTitleFiltering: false,
    })
      .then((result: any) => {
        this.members = result;
      })
      .catch((err: any) => {
        logger.error(err);
      });
  }

  private async getTitles() {
    let zrNummer = this.getZrNummerDependOnRole();
    this.actionGetTitlesMembers({
      zrNummer: zrNummer,
      isUserInAzureAdminGroupGetter: this.isUserInAzureAdminGroupGetter,
      isZrNummerFiltering: false,
    })
      .then((result: any) => {
        this.titles = result;
      })
      .catch((err: any) => {
        logger.error(err);
      });
  }
  statuses: any = [];
  members = [];
  titles = [];
  //private isShowAllData:boolean = true;
  wochenabschluesseSearchData = wochenabschluesseSearchData.defaultData();

  changeDate(value: any) {
    this.hideSubTanle();
    this.wochenabschluesseSearchData.datum = value || undefined; // new Date(value).toJSON();

    // (GSP-102) Always take into account the `zrNummer` to avoid show all records for `gsUser`
    const payload = Object.assign({}, this.wochenabschluesseSearchData);
    payload.isUserInAzureAdminGroup = this.isUserInAzureAdminGroupGetter;
    payload.zrNummer = this.getZrNummerDependOnRole();

    this.wochenabschluesseSearchData = Object.assign({}, payload); // workaround (sometimes data change is just ignored by watcher)
  }

  showMenu() {
    this.refRechnungsnummerCombobox.activateMenu();
  }

  reloadRechnungsnummersList: boolean = false;
  reloadStatusesList: boolean = false;
  reloadMembersList: boolean = false;
  reloadSuppliersList: boolean = false;

  //#endregion

  searchRechnungsnummerInput = '';
  searchStatusInput = '';
  searchMembersInput = '';
  searchTitlesInput = '';
  searchModelNameInput = '';
  searchSupplierInput = '';

  clearSearchInput() {
    this.searchRechnungsnummerInput = '';
    this.searchStatusInput = '';
    this.searchMembersInput = '';
    this.searchTitlesInput = '';
    this.searchModelNameInput = '';
    this.searchSupplierInput = '';
  }

  clearSearchFormData() {
    this.actionResetwochenabschluesseSearchFormData();
  }

  private allowedDates(date: string) {
    const dateValue = new Date(date);
    let isFriday = dateValue.getDay() === 5;
    const today = new Date();
    let twoWeeksFromToday = new Date(today.getTime() + 12096e5 - 864e5); // `12096e5` is 14 days in milliseconds // (GSP-098) use 13 days (not 14) for 2 weeks after to be able to select again the Friday that 2 weeks from current Friday (otherwise it will be selected by default but you could not select it again manually)
    //`864e5` = 86400000 is 1 day in milliseconds

    if (this.isTodayMondayOrTuesday()) {
      twoWeeksFromToday = new Date(today.getTime() + 6048e5); // add 7 days in milliseconds to make next Friday valid (in 11 or 10 days (from Mon and Tue), not in 18 and 17 days as was before)
    }

    const isDateMoreThan2Weeks = dateValue > twoWeeksFromToday;

    return isFriday; // && isDateMoreThan2Weeks;
  }

  private isTodayMondayOrTuesday() {
    const today = new Date();
    const isMondayOrTuesday = today.getDay() === 1 || today.getDay() == 2;
    return isMondayOrTuesday;
  }

  private changeListData(value: boolean) {
    this.hideSubTanle();

    //this.wochenabschluesseSearchData.isShowAllData = value;
    //this.wochenabschluesseSearchData = Object.assign({}, this.wochenabschluesseSearchData);
    this.wochenabschluesseSearchData.isShowAllData = value; // new Date(value).toJSON();
    //this.getWochenabschluesseSearchData.isShowAllData = value;
    // (GSP-102) Always take into account the `zrNummer` to avoid show all records for `gsUser`
    const payload = Object.assign({}, this.wochenabschluesseSearchData);
    payload.isUserInAzureAdminGroup = this.isUserInAzureAdminGroupGetter;
    payload.zrNummer = this.getZrNummerDependOnRole();
    this.wochenabschluesseSearchData = Object.assign({}, payload);
  }

  filterTitles() {
    this.hideSubTanle();

    let membersList: string = '';
    let isZrNummerFiltering: boolean = false;
    if (this.wochenabschluesseSearchData.receiverNumbers.length > 0) {
      isZrNummerFiltering = true;
      membersList = this.wochenabschluesseSearchData.receiverNumbers.join(',');
    } else {
      isZrNummerFiltering = false;
      membersList = this.getZrNummerDependOnRole();
    }
    this.actionGetTitlesMembers({
      zrNummer: membersList,
      isUserInAzureAdminGroupGetter: this.isUserInAzureAdminGroupGetter,
      isZrNummerFiltering: isZrNummerFiltering,
    })
      .then((result: any) => {
        this.titles = result;
      })
      .catch((err: any) => {
        logger.error(err);
      });
  }

  filterMembers() {
    this.hideSubTanle();
    let titlesList: any = '';
    let isTitleFiltering: boolean = false;
    if (this.wochenabschluesseSearchData.titles.length > 0) {
      isTitleFiltering = true;
      titlesList = this.wochenabschluesseSearchData.titles.join(',');
    } else {
      titlesList = null;
      isTitleFiltering = false;
    }
    let zrNummer = this.getZrNummerDependOnRole();
    this.actionGetSupplierMembers({
      zrNummer: zrNummer,
      isUserInAzureAdminGroupGetter: this.isUserInAzureAdminGroupGetter,
      titles: titlesList,
      isTitleFiltering: isTitleFiltering,
    })
      .then((result: any) => {
        this.members = result;
      })
      .catch((err: any) => {
        logger.error(err);
      });
  }
  private hideSubTanle() {
    let expandedLists = document.querySelectorAll<HTMLElement>('.mdi-chevron-down.mdi-flip-v');
    expandedLists.forEach((element) => {
      element.click();
    });
  }
}
